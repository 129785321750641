import React from "react"
import styled from "styled-components"
import { LightButton } from "components/button"
import { Link } from "gatsby"

const StyledFooter = styled.footer`
  position: relative;
  z-index: 5;
  color: var(--color-white);
  padding: 3em 0;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    /*
    background-color: rgba(0,0,0,0.925);
    backdrop-filter: blur(8px) saturate(3) contrast(2);
    */
   background-color: var(--color-black);
  }
  & > div {
    font-size: 0.75em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-top: 1px solid rgba(255,255,255,0.25);
    margin-top: 3em;
    padding-top: 2em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      opacity: 0.6;
      transition: opacity 0.2s ease;
      .can-hover &:hover,
      a:active {
        opacity: 1;
        text-decoration: underline;
      }
    }
    a + a {
      margin-left: 1.5em;
    }
    span {
      opacity: 0.8;
    }
  }

  @media ( min-width: 54em ) {
    display: grid;
    grid-template: auto / 1fr 2fr;
    grid-gap: 1em 3em;
    place-items: start;
    & h3 {
      grid-row: auto / span 2;
      margin-top: 0.6em;
    }
    p {
      margin-top: 0.9em;
    }
    & > div {
      grid-column: auto / span 2;
    }
  }
  @media ( min-width: 60em ) {
    .show-player &::before {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
      border-left: 1px solid rgba(255,255,255,0.2);
    }
  }
`

const Footer = (props) => (
  <StyledFooter>
    <h3>The Journalism behind the Music</h3>
    <p>All music in this project is based on The Outlaw Ocean, a New York Times Best-Selling book by Ian Urbina that chronicles lawlessness at sea around the world. This reporting touches on a diversity of abuses ranging from illegal and overfishing, arms trafficking at sea, human slavery, gun running, intentional dumping, murder of stowaways, thievery of ships and other topics.</p>
    <LightButton as="a" href="https://www.theoutlawocean.com" target="_blank" rel="noopener noreferrer">Learn More</LightButton>
    <div rel="contentinfo">
      <div>
        <a href="mailto:media@theoutlawocean.com" title="Email The Outlaw Ocean Project" inverse={true}>Questions? Email us.</a>
        <Link to="/faqs" title="Frequently asked questions">FAQs</Link>
      </div>
      <a href="https://www.studiocedar.com" target="_blank" rel="noopener noreferrer">Site Credit</a>
    </div>
  </StyledFooter>
)

export { StyledFooter }
export default Footer
