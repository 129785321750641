import React from "react"
import styled, { css } from "styled-components"
import IconApple from "components/svg/IconApple"
import IconSpotify from "components/svg/IconSpotify"
import IconAmazon from "components/svg/IconAmazon"
import IconSoundcloud from "components/svg/iconSoundcloud"

const StyledAlbumLinks = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
  ${props => props.isSmall && css`
    @media ( min-width: 48em ) {
      font-size: 0.875em;
    }
  `}
	a {
    display: flex;
		align-items: center;
    transition: opacity 0.2s ease;
    &:not(:last-of-type) {
      margin-right: 1em;
    }
	}
`

const AlbumLinks = (props) => {

  const album = props.albumData
  
  const hasLinks = ( album.link_spotify || album.link_apple /*|| album.link_amazon*/ || album.link_soundcloud ) ? true : false

  const links = hasLinks ? [
      {
        url: album.link_apple ? album.link_apple : false,
        className: 'link_apple',
        title: `Apple Music`,
        icon: <IconApple />
      },
      {
        url: album.link_spotify ? album.link_spotify : false,
        className: 'link_spotify',
        title: `Spotify`,
        icon: <IconSpotify />
      },
      /*{
        url: album.link_amazon ? album.link_amazon : false,
        className: 'link_amazon',
        title: `Amazon`,
        icon: <IconAmazon />
      },*/
      {
        url: album.link_soundcloud ? album.link_soundcloud : false,
        className: 'link_soundcloud',
        title: `Soundcloud`,
        icon: <IconSoundcloud />
      },
    ]
    :
    false
    
  return (
    <>
      {links &&
        <StyledAlbumLinks {...props}>
          {links.map(( link ) => { return (
            link.url ? <a href={link.url} title={`Listen on ${link.title}`} target="_blank" rel="noopener noreferrer">{link.icon}</a> : ``
          )})}
        </StyledAlbumLinks>
      }
    </>
  )
}

export { StyledAlbumLinks }

export default AlbumLinks