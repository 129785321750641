import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import usePlayer from "hooks/usePlayer"
import { StyledPlayer } from "components/player"

import Radio from "components/radio"

const StyledNav = styled.header`
  position: sticky;
  z-index: 100;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 6% 0.9375em;
  color: var(--color-background);

  @media ( min-width: 60em ) {
    margin: 0 auto;
    padding-right: 6%;
    padding-left: 6%;
    .show-player & {
      max-width: none;
    }
  }
  @media ( min-width: 70em ) {
    max-width: 80em;
    padding-right: 3em;
    padding-left: 3em;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: blur(8px) saturate(3) contrast(3);
    opacity: 1;
    transition: opacity: 0.3s ease;
  }
  &.nav-opened::before {
    opacity: 0;
  }
  h1 {
    font-size: 1.125em;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
    strong {
      font-weight: 700;
    }
  }

  @media ( max-width: 48em ) {
    h1 {
      position: relative;
      z-index: 10;
    }
    input:checked ~ h1,
    &.nav-opened h1 {
      color: var(--color-white);
    }
  }
  @media ( min-width: 48em ) {
    h1 {
      margin-top: 0.175em;
      margin-bottom: 0.025em;
    }
  }
  @media ( max-width: 54em ) {
    h1 {
      width: 100%;
      max-width: 11em;
      strong {
        display: block;
      }
    }
  }

  @media ( min-width: 54em ) {
    h1 {
      white-space: nowrap;
    }
  }


  [for="navToggle"] {
    position: relative;
    z-index: 11;
    display: block;
    height: 2em;
    width: 2em;
    text-indent: -999em;
    transform: translate3d(0,0,0) rotate(0);
    transition: transform 0.4s ease;
  }
  input:checked ~ [for="navToggle"],
  &.nav-opened [for="navToggle"] {
    color: var(--color-white);
    transform: translate3d(0,0,0) rotate(45deg);
  }
  [for="navToggle"]::before,
  [for="navToggle"]::after {
    content: '';
    position: absolute;
    left: 0.375em;
    top: calc(50% - 1px);
    width: 1.25em;
    height: 2px;
    background-color: currentColor;
  }
  [for="navToggle"]::before {
  }
  [for="navToggle"]::after {
    transform: rotate(90deg);
  }
  @media ( min-width: 48em ) {
    [for="navToggle"] {
      display: none;
    }
  }
`

const StyledNavBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  background-color: var(--color-black);
  opacity: 1;
  transition: opacity 0.3s ease;
  .hasScrolled & {
    opacity: 0;
  }
  @media ( max-width: 48em ) {
    input:checked ~ &,
    ${StyledNav}.nav-opened & {
      opacity: 0;
    }
  }
`

const StyledNavBackgroundImage = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.3s ease;
  opacity: 0.7;
  &[data-inactive] {
    opacity: 0;
  }
  .hasScrolled &:not([data-inactive]) {
    opacity: 0.7;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(8px) saturate(1.2) contrast(2);
  }
  .gatsby-image-wrapper {
    transform: translate3d(0,-20%,0);
  }
  @media ( max-width: 48em ) {
    input:checked ~ &,
    ${StyledNav}.nav-opened & {
      opacity: 0;
    }
  }
`

const StyledNavLinks = styled.nav`
  display: flex;
  
  a, 
  & > span {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
  }
  & > span > span {
    position: relative;
    z-index: 20;
  }
  a,
  & > span > span {
    .can-hover &:hover,
    &:active {
      opacity: 0.7;
    }
    &.is-active,
    .can-hover &:hover,
    &:active {
      text-decoration: underline
    }
  }
  @media ( max-width: 48em ) {
    
    position: fixed;
    display: flex;
    align-items: flex-start;
    z-index: 9;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: -120vw;
    padding: 6%;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0,0,0,0.9);
    backdrop-filter: blur(8px) saturate(3) contrast(3);
    color: white;
    transform: scale(1.2);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.2s ease, left 0s linear 0.3s;
    
    input:checked ~ &,
    ${StyledNav}.nav-opened & {
      left: 0;
      transform: scale(1);
      opacity: 1;
      transition: transform 0.3s ease, opacity 0.2s ease, left 0s linear;
    }
    
    a,
    & > span {
      text-transform: none;
      letter-spacing: normal;
      font-size: 1.5em;
    }
    a:not(:last-of-type),
    & > span {
      margin-bottom: 1em;
    }
  }
  @media ( min-width: 48em ) {
    justify-content: space-between;
    align-items: center;
    a,
    & > span {
      font-size: 0.75em;
      letter-spacing: 0.075em;
    }
    a:not(:last-of-type),
    & > span {
      margin-right: 2em;
    }
  }
`

const StyledPlayerToggle = styled.div`
  position: relative;
  font-size: 0.75em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.5em 0.75em 0.45em;
  border-radius: calc(2 * var(--border-radius));
  margin: 0 1.25em 0 auto;
  box-shadow: 0 0.125em 0.5em rgba(0,0,0,0.3);
  background-color: rgba(255,255,255,0.2);
  @media ( min-width: 48em ) {
    display: none;
  }
`

const StyledNavDropdown = styled.div`
  position: relative;
  cursor: default;
  
  @media ( max-width: 48em ) {
    border: 1px solid rgba(255,255,255,0.1);
    background-color: rgba(255,255,255,0.05);
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: calc(2 * var(--border-radius));
    width: calc( 100vw - 16vw );
  }
  @media ( min-width: 48em ) {
    display: none;
    &.is-active {
      display: block;
    }
    position: absolute;
    z-index: 10;
    margin-top: 1em;
    background-color: var(--color-gray-dark);
    border-radius: var(--border-radius);
    box-shadow: 0 0.25em 1.5em -0.25em rgba(0,0,0,0.2);
    padding: 1.25em 1.5em;
    width: 16em;
    left: 50%;
    margin-left: -8em;
    color: var(--color-white);
    grid-template: 1fr / 1fr 3fr;
    grid-gap: 1em;
    &::before {
      content: '';
      position: absolute;
      height: 1em;
      width: 1em;
      top: 0;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      background-color: inherit;
      transform: rotate(45deg);
    }
  }
`

const Nav = props => {
  const [showNav, setShowNav] = useState(false)
  const [showRadio, setShowRadio] = useState(false)

  const { 
    currentAlbum,
    currentTracks,
    togglePlayer
  } = usePlayer()

  useEffect(() => {

    if ( document.querySelector('main') ) {
      document.querySelector('main').addEventListener('click', function () {
        setShowRadio(false)
      })
    }
  })

  const handleClickNavToggle = function(e) {
    e.preventDefault()
    setShowNav( ! showNav )
  }

  const hideNav = function () {
    setShowNav( false )
  }

  const handleNavClick = function () {
    setShowNav( false )
    setShowRadio( false )
  }

  return (
    <StyledNav className={showNav ? `nav-opened` : null}>
      <input id="navToggle" type="checkbox" hidden />
      <StyledNavBackground />
      <StyledNavBackgroundImage data-inactive={ ! currentAlbum ? `true` : null}>
        {currentAlbum && currentAlbum.cover && currentAlbum.cover.localFile &&
          <Img fluid={currentAlbum.cover.localFile.childImageSharp.fluid} alt={`${currentAlbum.title} by`} />
        }
      </StyledNavBackgroundImage>
      <h1><Link to="/" onClick={(e) => setShowNav(0)}><strong>The Outlaw Ocean</strong> Music Project</Link></h1>
      { ( currentAlbum || currentTracks ) &&
        <StyledPlayerToggle onClick={(e) => togglePlayer()}>Player</StyledPlayerToggle>
      }
      <label htmlFor="navToggle" onClick={(e) => handleClickNavToggle(e)}>Menu</label>
      <StyledNavLinks role="navigation">
        <span className={showRadio ? `is-active` : null}>
          <span onClick={(e) => setShowRadio( ! showRadio )}>Listen</span>
          <StyledNavDropdown className={showRadio ? `is-active` : null}>
            <Radio setShowRadio={setShowRadio} hideNav={hideNav} />
          </StyledNavDropdown>
        </span>
        <Link to="/albums" activeClassName="is-active" onClick={(e) => handleNavClick()}>Albums</Link>
        <Link to="/about" activeClassName="is-active" onClick={(e) => handleNavClick()}>About</Link>
        <Link to="/press" activeClassName="is-active" onClick={(e) => handleNavClick()}>Press</Link>

<Link to="/faqs" activeClassName="is-active" onClick={(e) => handleNavClick()}>FAQs</Link>

      </StyledNavLinks>
    </StyledNav>
  )
}

export default Nav
