import styled from "styled-components"

const Button = styled.button`
  appearance: none;
  position: relative;
  display: inline-block;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font-sans);
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.0375em;
  line-height: 2.9;
  text-decoration: none;
  cursor: default;
  padding: 0 1.125em 0 1.25em;
  height: 2.875em;
  border: 0;
  i {
    positin: relative;
    display: inline-block;
    vertical-align: top;
    width: 0.75em;
    height: 0.625em;
    margin-right: 0.75em;
    transform: translate3d(0,-0.125em,0);
    path,
    polygon {
      fill: currentColor;
    }
  }
  .can-hover &:hover,
  &:active {
    background-color: var(--color-gray-dark);
  }
  &[href] {
    cursor: pointer;
  }
`

const LightButton = styled(Button)`
  background-color: var(--color-background);
  color: var(--color-black);
  .can-hover &:hover,
  &:active {
    color: var(--color-white);
  }
`
export { Button, LightButton }
