import React, { useState } from "react"

const PlayerContext = React.createContext([{}, () => {}])

const PlayerProvider = (props) => {
	const [state, setState] = useState({ 
    currentArtist: null,
    currentTracks: null,
    currentAlbum: null,
    currentTrackIndex: null,
    currentTrackTitle: null,
    currentVideoId: null,
		playerState: `unstarted`,
		playerStatus: false,
		isPlaying: false,
		player: null,
		trackDuration: 0,
    playerVisibility: 'hidden'
	})
	return (
		<PlayerContext.Provider value={[state, setState]}>
			{props.children}
		</PlayerContext.Provider>
	)
}

export { PlayerContext, PlayerProvider }