import React, { useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { LightButton } from "components/button"
import { noise } from "components/css/noise"


const StyledHero = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  height: clamp(20em, 60vw, 40em);
  text-align: center;
  h1 {
    position: relative;
    z-indeX: 5;
    line-height: 1;
    font-size: 2.25em;
    font-weight: 400;
    text-align: center;
    margin: 0.75em 0 1em;
    text-shadow: 0 0 0.75em rgba(0,0,0,0.9), 0 0 0.125em rgba(0,0,0,0.9);
  }
  @media ( max-width: 48em ) {
    h1 {
      font-size: 2em;
    }
  }
  @media ( min-width: 48em ) {
    height: clamp(20em, 50vw, 40em);
  }
`

const StyledHeroBackground = styled.figure`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  overflow: hidden;
  div {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  span {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.05);
    backdrop-filter: blur(8px) saturate(3) contrast(2);
    opacity: 0.5;
  }
  ${noise}
`

const Hero = props => {

  function heroOverlayOpacity() {
    const heroContent = document.getElementById('hero-content')
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const contentOpacityValue = 1.125 - ( ( scrollTop * .25 ) / 100 )
  
    //heroFilter.style.opacity = opacityValue < 1 ? opacityValue : 1
    heroContent.style.opacity = contentOpacityValue > 0 ? contentOpacityValue : 0
  
  
  }
  
  useEffect(() => {
    const heroInterval = setInterval( heroOverlayOpacity, 50 )
    return function cleanup() {
      clearInterval(heroInterval)
    }
  })

  return (
    <StyledHero>
      <StyledHeroBackground>
        <Img fluid={props.image} alt="" />
        <span id="hero-filter"></span>
      </StyledHeroBackground>
      <div id="hero-content">
        <h1>Making Music <strong>from Journalism</strong></h1>
        <LightButton as={Link} to="/albums">Explore the Artists</LightButton>
      </div>
    </StyledHero>
  )
}

export { StyledHero }

export default Hero