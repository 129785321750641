import React from "react"
import styled, { css } from "styled-components"
import TrackItem from "components/album/tracks/item"

const StyledTrackList = styled.div`
  position: relative;
  width: 100%;
`

const StyledTrackListItem = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  width: 100%;
  padding: 0 1.5em 0 0;
  margin: 0.75em 0;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.375em;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform: translate3d(calc(100% + 1px),0,0);
  }
  .can-hover &:hover::before,
  &:active::before,
  &[data-active]::before {
    transform: translate3d(0,0,0);
  }
  i {
    color: var(--color-gray);
    margin-right: 1.5em;
    font-size: 0.8125em;
    font-style: normal;
  }
  .can-hover &:hover i,
  &:active i,
  &[data-active] i {
    opacity: 0;
  }
  &.is-single i {
    opacity: 0;
  }
  span {
    font-size: 0.9375em;
    font-weight: 500;
    letter-spacing: 0.025em;
    ${props => props.isSmall && css`
      @media ( min-width: 48em ) {
        font-size: 0.8125em;
      }
    `}
  }
  &[data-active] span:not(.star) {
    font-weight: 700;
  }

  span.star {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    margin-top: -0.1em;
    margin-left: -0.4em;
  }
  span.star::before {
    content: '\\22c6';
    position: absolute;
    font-size: 3em;
    font-weight: normal;
    line-height: 0;
  }

  .can-hover &:hover span.star,
  &:active span.star,
  &[data-active] span.star {
    opacity: 0;
  }
  span small {
    display: block;
    font-size: 0.875em;
    font-weight: 400;
  }
  span small.inline {
    display: inline;
  }
`

const StyledTrackListItemIcon = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 0.375em;
  height: 0.75em;
  width: 0.75em;
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%,0,0);
    max-width: 100%;
    max-height: 100%;
    path,
    polygon {
      fill: currentColor;
    }
  }
  .can-hover ${StyledTrackListItem}:hover &,
  ${StyledTrackListItem}:active &,
  [data-active] & {
    display: block;
  }
`

const TrackList = props => {
  
  const album = props.albumData || false
  const tracks = props.trackData || false
  const trackList = album ? album.tracks : ( tracks ? tracks : false )

  return (
    <StyledTrackList>
      {trackList && trackList.map((track, index) => 
        <TrackItem
          key={track.id}
          track={track}
          trackIndex={index}
          album={album}
          trackList={trackList}
          hideNumber={props.hideNumber ? props.hideNumber : false}
        />
      )}
    </StyledTrackList>
  )
}

export { StyledTrackList }

export default TrackList