// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-albums-js": () => import("./../../../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-apology-from-ian-js": () => import("./../../../src/pages/apology-from-ian.js" /* webpackChunkName: "component---src-pages-apology-from-ian-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-press-sound-sniffer-js": () => import("./../../../src/pages/press-sound-sniffer.js" /* webpackChunkName: "component---src-pages-press-sound-sniffer-js" */),
  "component---src-pages-radio-js": () => import("./../../../src/pages/radio.js" /* webpackChunkName: "component---src-pages-radio-js" */),
  "component---src-pages-scripps-howard-award-excellence-in-innovation-in-journalism-js": () => import("./../../../src/pages/scripps-howard-award-excellence-in-innovation-in-journalism.js" /* webpackChunkName: "component---src-pages-scripps-howard-award-excellence-in-innovation-in-journalism-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-genres-js": () => import("./../../../src/templates/genres.js" /* webpackChunkName: "component---src-templates-genres-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */)
}

