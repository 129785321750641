import React from "react"
import { css, keyframes } from "styled-components"

const grain = keyframes`
  0%, 100% {
    transform:translate(0, 0)
  }

  10% {
    transform:translate(-5%, -10%)
  }

  20% {
    transform:translate(-15%, 5%)
  }

  30% {
    transform:translate(7%, -25%)
  }

  40% {
    transform:translate(-5%, 25%)
  }

  50% {
    transform:translate(-15%, 10%)
  }

  60% {
    transform:translate(15%, 0%)
  }

  70% {
    transform:translate(0%, 15%)
  }

  80% {
    transform:translate(3%, 35%)
  }

  90% {
    transform:translate(-10%, 10%)
  }
`

const noise = css`
  &::after {
    animation: ${grain} 8s steps(10) infinite;
    background: url(/noise.png);
    content: "";
    display: block;
    height: 300%;
    left: -100%;
    position: absolute;
    top: -100%;
    width: 300%;
    z-index: 2;
  }
`

export { noise }