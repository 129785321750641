import React from "react"

const IconStar = () => (
  <svg viewBox="0 0 65 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="32.1428571 0 40.1785714 22.6666667 64.2857143 22.6666667 45.5357143 37.3333333 52.2321429 60 32.1428571 46.6666667 12.0535714 60 18.75 37.3333333 0 22.6666667 24.1071429 22.6666667"></polygon>
    </g>
</svg>
)

export default IconStar