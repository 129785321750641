import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import Nav from "components/nav"
import Footer from "components/footer"
import Player from "components/player"
import usePlayer from "hooks/usePlayer"
import Hero, { StyledHero } from "components/hero"
import "fonts/fonts.css"
import "components/layout.css"

const StyledSiteWrap = styled.div`
  margin: 0;
  padding: 0 6%;
  @media ( min-width: 60em ) {
    margin: 0 auto;
  }
  @media ( min-width: 70em ) {
    max-width: 80em;
    padding: 0 3em;
  }
  @media ( min-width: 60em ) {
    .show-player & {
      position: relative;
      margin-right: calc(200px + 3em);
      margin-left: 0;
      margin-left: calc(200px + 3em);
      margin-right: 0;
    }
  }
`


const registerTouchables = () => {
  const touchables = document.querySelectorAll('button, a[href], label, summary');
  let touchablesLength = touchables.length;


  while ( touchablesLength-- ) {
    touchables[touchablesLength].addEventListener('touchstart', () => {}, {passive: true});
    touchables[touchablesLength].addEventListener('touchend', () => {}, {passive: true});
  }
}

const Layout = props => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            opengraph {
              title
              image
              description
              url
            }
          }
        }
      }
    `
  )

  const dataOpengraph = data.site.siteMetadata.opengraph

  const [scrolled, setScrolled] = useState(false)
  
	const {  
    currentAlbum,
    currentTracks,
    playerVisibility
	} = usePlayer()

  const scrolledClass = scrolled ? ` hasScrolled` : ``
  const playerClass = currentAlbum || currentTracks ? `has-player` : ``
  const wrapperClassName = `${scrolledClass} ${playerClass}`

  useEffect(() => {
    registerTouchables();
    if ( ! matchMedia(`(hover: none)`).matches ) {
      document.getElementsByTagName( 'html' )[0].classList.add(`can-hover`)
    }

    setInterval(() => {
      window.pageYOffset > 10 ? setScrolled(true) : setScrolled(false)
    },100)

  })

  return (
    <>
      <Helmet>
        <meta property="og:title" content={dataOpengraph.title} />
        <meta property="og:image" content={dataOpengraph.image} />
        <meta property="og:description" content={dataOpengraph.description} />
        <meta property="og:url" content={dataOpengraph.url} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" /> 
      </Helmet>
      <Nav />
      <div className={playerVisibility === "full" ? "show-player" : undefined}>
        <StyledSiteWrap className={playerVisibility !== "hidden" ? "has-player" : undefined}>
          <div className={scrolled ? ` hasScrolled` : undefined}>
              {props.children}
            </div>
          <Footer />
          <Player />
        </StyledSiteWrap>
      </div>
    </>
  )
}

export default Layout
