import React from "react"
import styled from "styled-components"

const StyledIconSoundcloud = styled.svg`
  height: 1.25em;
  width: 2.25em;
  path {
    fill: currentColor;
  }
`

const IconSoundcloud = () => (
    <StyledIconSoundcloud viewBox="0 0 35 18" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M22.119,3.37507799e-14 C25.784,3.37507799e-14 28.756,3.07 28.756,6.857 C28.756,7.249 28.717,7.627 28.656,8 L28.656,8 L29.861,8 C32.303,8 34.285,10.045 34.285,12.569 C34.285,15.095 32.303,17.141 29.861,17.141 L29.861,17.141 L17.142,17.141 L17.142,2.348 C18.358,0.916 20.131,3.37507799e-14 22.119,3.37507799e-14 Z M6.429,7.999 L6.429,17.141 L4.424,17.141 L4.286,17.127 L4.286,8.013 C4.286,8.013 4.424,8 4.424,7.999 L4.424,7.999 L6.429,7.999 Z M12.857,3.478 C13.634,3.58 14.359,3.843 15,4.243 L15,4.243 L15,17.141 L12.857,17.141 Z M10.714,3.648 L10.714,17.141 L8.571,17.141 L8.571,4.83 C9.186,4.288 9.91,3.876 10.714,3.648 L10.714,3.648 Z M2.142,8.673 L2.142,16.469 C0.864,15.668 2.84217094e-14,14.228 2.84217094e-14,12.569 C2.84217094e-14,10.913 0.864,9.473 2.142,8.673 L2.142,8.673 Z"></path></g></StyledIconSoundcloud>
)

export default IconSoundcloud
