import React from "react"
import { PlayerProvider } from "contexts/playerContext"
import Layout from "components/layout"

export const wrapRootElement = ({ element, props }) => (
	<PlayerProvider>
		{element}
	</PlayerProvider>
)

export const wrapPageElement = ({ element, props }) => (
	<Layout {...props}>
    {element}
  </Layout>
)
