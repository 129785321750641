import React, { useState } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import usePlayer from "hooks/usePlayer"
import AlbumLinks from "components/album/links"
import TrackList from "components/album/tracks/list"



const StyledPlayer = styled.aside`
  position: fixed;
  display: none;
  z-index: 98;
  background-color: var(--color-black);
  color: var(--color-white);
  .has-player & {
    display: block;
  }

  @media ( max-width: 48em ) {
    top: 4.125em;
    bottom: 0;
    width: 100vw;
    left: -120vw;
    transform: scale(1.2);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.2s ease, left 0s linear 0.3s;
    .show-player  & {
      left: 0;
      transform: scale(1);
      opacity: 1;
      transition: transform 0.3s ease, opacity 0.2s ease, left 0s linear;
    }
  }
  @media ( min-width: 60em ) {
    bottom: 0;
    right: 1.5em;
    width: calc(200px + 3em);
    padding: 0;
    top: calc(100% - 200px - 7em);
    left: auto;
    right: 0;
    bottom: 0;
    .show-player .has-player & {
      top: 3.25em;
      left: 0;
      right: auto;
    }
  }
`

const StyledPlayerContent = styled.div`
  position: relative;
  @media ( max-width: 48em ) {
    position: absolute;
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 1em;
    padding: 0 1.5em 2em;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    user-select: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media ( min-width: 48em ) {
    z-index: 10;
    /*
    z-index: 10;
    background-color: rgba(255,255,255,0.95);
    backdrop-filter: blur(8px) saturate(4) contrast(4);
    border-radius: calc(2* var(--border-radius))  calc(2*var(--border-radius)) 0 0;
    box-shadow: 0 0 2em -0.75em rgba(0,0,0,0.4);
    */
   /*
   &::after {
     content: '';
     position: absolute;
     right: 0;
     bottom: 0;
     left: 0;
     height: 1em;
     background-image: linear-gradient(to top,rgba(255,255,255,0.8),rgba(255,255,255,0.6) 50%,rgba(255,255,255,0) 100%);
   }*/
  }
  @media ( min-width: 60em ) {
    padding: 1.25em 1.25em 1.25em 0;
    padding: 1.25em;
    padding: 0;
    display: grid;
    grid-template: 1fr 3fr auto / 100%;
    height: 100%;
  }
`

const StyledPlayerArtwork = styled.div`
  position: relative;
  .is-active & {
    display: block;
  }
  ${props => props.isHidden && props.isHidden === `true` && css`
    display: none !important;
  `}
  box-shadow: 0 0.25em 1.375em -0.75em rgba(0,0,0,0.6);
  figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-color: var(--color-black);
    overflow: hidden;
    iframe {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  @media ( max-width: 48em ) {
    width: 14em;
  }
`

const StyledPlayerHeader = styled.header`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media ( max-width: 48em ) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1.25em;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  h3 {
    font-size: 1em;
    font-weight: 500;
    margin: 0 0 0.75em;
    a {
      text-decoration: underline;
    }
    .can-hover & a:hover,
    a:active {
      color: var(--color-gray);
    }
  }
  @media ( min-width: 48em ) {
    h2 {
      font-size: 0.875em;
    }
    h3 {
      font-size: 0.875em;
    }
  }
`


const StyledPlayerContentSticky = styled.div`
  position: relative;
  z-index: 10;
  top: 0;
  padding: 1em 1.5em 1.5em;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  @media ( max-width: 48em ) {
    position: sticky;
    margin-right: -1.5em;
    margin-left: -1.5em;
    padding: 4em 0 1.5em;
    background-color: var(--color-black);
  }
  @media ( min-width: 60em ) {
    border: 0;
    .show-player .has-player & {
      padding-top: 1.5em;
    }
  }
`

const StyledPlayerTracks = styled.div`
  @media ( min-width: 48em ) {
    display: none;
    .show-player .has-player & {
      display: block;
    }
    border-top: 1px solid rgba(255,255,255,0.2);
    padding-left: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    user-select: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledPlayerControls = styled.div`
  grid-row: 1;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  display: flex;
  .show-player .has-player & {
    grid-row: auto;
    border-bottom: 0;
    border-top: 1px solid rgba(255,255,255,0.2);
  }
  button {
    display: block;
    width: 50%;
    font-weight: 500;
    font-size: 0.75em;
    padding: 0.5em;
  }
  .can-hover & button:hover,
  button:active {
    opacity: 0.5;
  }
  @media ( max-width: 60em ) {
    display: none;
  }
`

const StyledPlayerClose = styled.div`
  position: absolute;
  display: block;
  height: 2.75em;
  width: 2.75em;
  top: 1.5em;
  right: 1.5em;
  overflow: hidden;
  text-indent: -999em;
  background-color: var(--color-white);
  color: var(--color-black);

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 0.75em;
    left: 0.75em;
    height: 2px;
    margin-top: -1px;
    background-color: currentcolor;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  @media ( min-width: 48em ) {
    top: 0;
    right: auto;
    left: 0;
    width: 1.5em;
    height: 1.5em;
    background-color: transparent;
    color: inherit;
    box-shadow: none;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    .show-player & {
      transform: rotate(45deg);
    }
    &::before,
    &::after {
      right: 0.375em;
      left: 0.375em;
    }
  }
  @media ( min-width: 60em ) {
    display: none;
  }
`


const Player = (props) => {

	const { 
    currentTracks,
    currentAlbum,
    setCurrentTrackIndex,
    currentTrackIndex,
    setPlayerState,
    hidePlayer,
    togglePlayer,
    closePlayer,
    playerVisibility
  } = usePlayer()

	return (
    <StyledPlayer className={ (currentAlbum !== null || currentTracks !== null) ? 'is-active' : 'is-active'}>
      <StyledPlayerContent>
        <StyledPlayerContentSticky>
          <StyledPlayerClose onClick={(e) => togglePlayer()}>Hide</StyledPlayerClose>
          <StyledPlayerArtwork
            isHidden={! currentAlbum && ! currentTracks ? `true` : `false`}
          >
            <figure>
              <div id="player-iframe"></div>
            </figure>
          </StyledPlayerArtwork>
          {currentAlbum &&
            <StyledPlayerHeader>
              <h2>{currentAlbum.title}</h2>
              <h3>album by <Link to={`/artists/${currentAlbum.artist.slug}`} onClick={(e) => hidePlayer()}>{currentAlbum.artist.name}</Link></h3>
              <AlbumLinks albumData={currentAlbum} isSmall="true" />
            </StyledPlayerHeader>
          }
          {currentTracks && currentTracks[currentTrackIndex] &&
            <StyledPlayerHeader>
              <h2>{currentTracks[currentTrackIndex].title}</h2>
              <h3>track by <Link to={`/artists/${currentTracks[currentTrackIndex].album.artist.slug}`} onClick={(e) => hidePlayer()}>{currentTracks[currentTrackIndex].album.artist.name}</Link></h3>
              <AlbumLinks albumData={currentTracks[currentTrackIndex].album} isSmall="true" />
            </StyledPlayerHeader>
          }
        </StyledPlayerContentSticky>
          {currentAlbum &&
            <StyledPlayerTracks>
              <TrackList 
                albumData={currentAlbum} 
                isSmall="true" 
              />
            </StyledPlayerTracks>
          }
          {currentTracks &&
            <StyledPlayerTracks>
              <TrackList 
                trackData={currentTracks} 
                hideNumber
                isSmall="true" 
              />
            </StyledPlayerTracks>
          }
          {(currentTracks || currentAlbum) &&
            <StyledPlayerControls>
              <button 
                type="button"
                onClick={togglePlayer}
              >{playerVisibility === "full" ? "Hide" : "Show"}</button>
              <button 
                type="button"
                onClick={closePlayer}
              >Close</button>
            </StyledPlayerControls>
          }
      </StyledPlayerContent>
		</StyledPlayer>
	)
}

export { StyledPlayerArtwork }

export default Player
