import { useContext } from "react"
import { PlayerContext } from "contexts/playerContext"

const usePlayer = () => {
	const [state, setState] = useContext(PlayerContext)
	
	/*
	function setPlayer( player ) {
		setState(state => ({ ...state, player: player  }))
	}
  */

  
  function loadVideoById( videoId ) {
    const globalPlayer = window.globalPlayer

    globalPlayer.loadVideoById( videoId )
    globalPlayer.playVideo()
  }

  function getVideoIdsFromAlbum( album ) {
    let videoIds = []
    
    album.tracks.map(( item ) => {
      return (
        videoIds.push(item.video_id)
      )
    })
    return videoIds
  }

  function getVideoIdsFromTrackList( tracks ) {
    let videoIds = []
    
    tracks.map(( item ) => {
      return (
        videoIds.push(item.video_id)
      )
    })
    return videoIds
  }

  function loadYouTubeAPI(callback) {
    console.log('load youtube script')
    const tag = document.createElement('script')

    tag.async = false
    tag.src = "https://www.youtube.com/iframe_api"
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    window.onYouTubeIframeAPIReady = () => {
      const globalPlayer = new window.YT.Player('player-iframe', {
        width: '640',
        height: '640',
        playerVars: {
          'modestbranding': 1,
          'playsinline': 1,
          'rel': 0,
          'loop': 1,
          //'controls': 0
        },
        events: {
          'onStateChange': function () {
            setCurrentTrackIndex( globalPlayer.getPlaylistIndex() )
            setPlayerState( globalPlayer.getPlayerState() )
          },
          'onReady': function () {
            globalPlayer.setLoop(true)
            window.globalPlayer = globalPlayer
            callback()
            return
          }
        }
      })
    }

  }
  
  
  function loadAndPlayTracks( tracks, initialIndex = 0 ) {
    if ( ! window.YT ) {
      loadYouTubeAPI(() => {
        loadAndPlayTracks(tracks, initialIndex)
      })
      return
    }
    if ( state.playerVisibility === "hidden" ) {
      setState(state => ({ ...state, playerVisibility: "full" }))
    }
    const globalPlayer = window.globalPlayer

    if ( ( state.currentTracks !== null && state.currentTracks === tracks ) && state.currentTrackIndex === initialIndex ) {
      if ( state.playerState === `playing` ) {
        globalPlayer.pauseVideo()
      }
      else {
        globalPlayer.playVideo()
      }
      return
    }

    const videoIdsArray = getVideoIdsFromTrackList( tracks )
    setState(state => ({ ...state, currentAlbum: null }))
    setState(state => ({ ...state, currentTracks: tracks }))
    globalPlayer.loadPlaylist( videoIdsArray, initialIndex )
    
    /*if ( state.currentTrackIndex === initialIndex ) {
      globalPlayer.playVideo()
      return
    }*/
    globalPlayer.playVideoAt(initialIndex)
    return
  }

  async function loadAndPlayPlaylist( album, initialIndex = 0 ) {
    if ( ! window.YT ) {
      loadYouTubeAPI(() => {
        loadAndPlayPlaylist(album, initialIndex)
      })
      return
    }
    if ( state.playerVisibility === "hidden" ) {
      setState(state => ({ ...state, playerVisibility: "full" }))
    }
    const globalPlayer = window.globalPlayer

    if ( ( state.currentAlbum !== null && state.currentAlbum.id === album.id ) && state.currentTrackIndex === initialIndex ) {
      if ( state.playerState === `playing` ) {
        globalPlayer.pauseVideo()
      }
      else {
        globalPlayer.playVideo()
      }
      return
    }

    const videoIdsArray = getVideoIdsFromAlbum( album )

    setState(state => ({ ...state, currentTracks: null }))
    setState(state => ({ ...state, currentAlbum: album }))
    globalPlayer.loadPlaylist( videoIdsArray, initialIndex )
    
    /*
    if ( state.currentAlbum === null || state.currentAlbum.id !== album.id ) {
      const videoIdsArray = getVideoIdsFromAlbum( album )

      setState(state => ({ ...state, currentAlbum: album }))
      globalPlayer.loadPlaylist( videoIdsArray, initialIndex )
    }
    */
    globalPlayer.playVideoAt(initialIndex)
    return
  }

  function setCurrentTrackIndex( newIndex ) {
    /// setState( state => ({ ...state, currentTrackTitle: state.currentAlbum.tracks[ newIndex ].title }))
    setState( state => ({ ...state, currentTrackIndex: newIndex }))
  }

  function setPlayerState( state ) {

    const stateString = playerStateToString( state )

    setState( state => ({ ...state, playerState: stateString }))
  }
	
	function playerStateToString( code ) {
		if ( code === -1 ) {
			return `unstarted`
		}
		else if ( code === 0 ) {
			return `done`
		}
		else if ( code === 1 ) {
			return `playing`
		}
		else if ( code === 2 ) {
			return `paused`
		}
		else if ( code === 3 ) {
			return `loading`
    }
    else if ( code === 5 ) {
      return `queued`
    }
  }
  
  function showPlayer() {
    if ( matchMedia(`(max-width: 48em)`).matches ) {
      const scrollY = window.pageYOffset
      
      document.body.style.top = `-${scrollY}px`;

      document.body.style.position = 'fixed';
      document.body.style.left = '0';
      document.body.style.right = '0';
    }
    //document.body.classList.add('show-player')
    setState(state => ({ ...state, playerVisibility: "full" }))
  }

  function hidePlayer() {
    if ( matchMedia(`(max-width: 48em)`).matches ) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = 'auto';
      document.body.style.right = 'auto';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    //document.body.classList.remove('show-player')
    setState(state => ({ ...state, playerVisibility: "small" }))
  }

  function togglePlayer() {
    if ( state.playerVisibility === "full" ) {
      hidePlayer()
    }
    else {
      showPlayer()
    }
  }

  function closePlayer() {
    //document.body.classList.remove('show-player')
    //document.querySelector('.has-player').classList.remove('has-player')
    if ( window.globalPlayer ) {
      window.globalPlayer.stopVideo()
    }
    setState(state => ({ ...state, playerVisibility: "hidden" }))
  }
	
	return {
    loadVideoById,
    loadAndPlayPlaylist,
    loadAndPlayTracks,
    currentTracks: state.currentTracks,
    currentAlbum: state.currentAlbum,
    currentTrackIndex: state.currentTrackIndex,
    currentTrackTitle: state.currentTrackTitle,
    currentVideoId: state.currentVideoId,
    setPlayerState,
		playerState: state.playerState,
    isPlaying: state.isPlaying,
    setCurrentTrackIndex,
    showPlayer,
    hidePlayer,
    togglePlayer,
    closePlayer,
    playerVisibility: state.playerVisibility
	}
}

export default usePlayer
