import React from "react"
import styled, { css } from "styled-components"

import usePlayer from "hooks/usePlayer"
import IconPlay from "components/svg/iconPlay"
import IconPause from "components/svg/iconPause"
import IconStar from "components/svg/iconStar"

const StyledTrackItem = styled.button`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 1.5em 0 1.625em;
  margin: 0.8125em 0;
  overflow: hidden;
  cursor: default;
  line-height: 1.1;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.375em;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform: translate3d(calc(100% + 1px),0,0);
  }
  .can-hover &:hover::after,
  &:active::after,
  &[data-active]::after {
    transform: translate3d(0,0,0);
  }
  &::before {
    content: attr(data-index) " ";
    position: absolute;
    color: var(--color-gray);
    margin-top: 0.25em;
    margin-right: 1.5em;
    font-size: 0.8125em;
    font-style: normal;
    left: 0.125em;
  }
  .can-hover &:hover::before,
  &:active::before,
  &[data-active]::before {
    opacity: 0;
  }
  &.is-single::before {
    display: none;
  }
  /*
  &.is-single::before {
    content: '\\22c6';
    position: absolute;
    font-size: 3em;
    font-weight: normal;
    color: currentColor;
    line-height: 0;
    left: -0.125em;
    margin-top: 0.15em;
  }
  */
  ${props => props.hideNumber && css`
    padding-left: 1.25em;
    &::before {
      display: none;
    }
  `}

  span {
    font-size: 0.875em;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 
    ${props => props.isSmall && css`
      @media ( min-width: 48em ) {
        font-size: 0.8125em;
      }
    `}
  }
  &[data-active] span:not(.star) {
    font-weight: 700;
  }

  
  span small {
    display: block;
    font-size: 0.875em;
    font-weight: 400;
  }
  span small.inline {
    display: inline;
  }
`


const StyledTrackItemIcon = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 0.2em;
  height: 0.75em;
  width: 0.75em;
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%,0,0);
    
    max-width: 100%;
    max-height: 100%;
    path,
    polygon {
      fill: currentColor;
    }
  }
  .can-hover ${StyledTrackItem}:hover &,
  ${StyledTrackItem}:active &,
  [data-active] & {
    display: block;
  }
`
const StyledTrackItemIconStar = styled.div`
  position: absolute;
  display: block;
  left: -0.375em;
  top: 0.125em;
  height: 1.5em;
  width: 1.5em;
  svg {
    position: absolute;
    display: block;
    width: 0.875em;
    height: 0.875em;
    top: 0;
    left: 50%;
    transform: translate3d(-50%,0,0);
    max-width: 100%;
    max-height: 100%;
    path,
    polygon {
      fill: currentColor;
    }
  }
  .can-hover ${StyledTrackItem}:hover &,
  ${StyledTrackItem}:active &,
  [data-active] & {
    display: none;
  }
`

const TrackItem = ({
  track,
  trackIndex,
  album,
  trackList,
  hideNumber
}) => {
  
  const tracks = trackList

  const { 
    loadAndPlayTracks,
    loadAndPlayPlaylist, 
    currentTrackIndex, 
    currentTracks,
    currentAlbum, 
    playerState,
    showPlayer
  } = usePlayer()

  let isSelected = null

  if ( album && ( currentAlbum && album && currentAlbum.id === album.id && currentTrackIndex === trackIndex ) ) {
    isSelected = "true"
  }
  else if ( ! album && (currentTracks && tracks && currentTrackIndex === trackIndex ) ) {
    isSelected = "true"
  }
  //const isSelected = ( ( currentAlbum && album && currentAlbum.id === album.id && currentTrackIndex === trackIndex ) || ( currentTracks && tracks && currentTrackIndex === trackIndex ) ) ? `true` : null

  let isActive = null

  if ( album &&  ( currentAlbum && currentAlbum.id === album.id && currentTrackIndex === trackIndex && playerState !== `unstarted` ) ) {
    isActive = playerState;
  }
  else if ( ! album && ( currentTracks && tracks && currentTrackIndex === trackIndex && playerState != `unstarted` ) ) {
    isActive = playerState
  }

  //const isActive = ( ( currentAlbum && currentAlbum.id === album.id && currentTrackIndex === trackIndex && playerState !== `unstarted` ) || ( currentTracks && tracks && currentTrackIndex === trackIndex && playerState != `unstarted` ) ) ? playerState : null

  function clickTrack( trackList, trackIndex ) {
    if ( album ) {
      loadAndPlayPlaylist(album, trackIndex)
    }
    else {
      loadAndPlayTracks(trackList, trackIndex)
    }
    showPlayer()
  }

  return (
    <StyledTrackItem 
      key={track.id}
      data-active={isSelected}
      data-index={trackIndex + 1}
      className={album && track.featured ? `is-single` : null} 
      onClick={(e) => clickTrack(trackList, trackIndex)}
      hideNumber={hideNumber ? hideNumber : false}
    >
      {isActive === null && album && track.featured && (
        <StyledTrackItemIconStar>
          <IconStar />
        </StyledTrackItemIconStar>
      )}
      <StyledTrackItemIcon>
        {isActive === `playing` ?
          <IconPause /> 
          :
          <IconPlay />
        }
      </StyledTrackItemIcon>
      <span>{track.title}
        {track.byline && track.byline !== `` && ! tracks && 
          <small>by {track.byline}</small>
        }
      </span>
    </StyledTrackItem>
  )
}

export default TrackItem