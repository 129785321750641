import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import usePlayer from "hooks/usePlayer"
import Logo from "components/svg/Logo"

const StyledRadio = styled.div`
  @media ( max-width: 48em ) {
  }
  @media ( min-width: 48em ) {
    > div:first-of-type {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
`

const StyledRadioButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: 1.125em;
  color: inherit;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: var(--border-radius);
  padding: 0.5em;
  font-weight: 500;
  line-height: 1;
  cursor: default;
  &:not(:last-of-type) {
    margin-bottom: 0.125em;
  }
  i {
    font-style: normal;
    font-size: 0.8125em;
    padding: 0.25em 0.5em;
    background-color: var(--color-black);
    border-radius: var(--border-radius);
    line-height: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    background-color: rgba(170,170,170,0.5);
    opacity: 0;
    transition: opacity 0.1s ease;
  }
  .can-hover &:hover::before,
  &:active::before {
    opacity: 0.5;
  }
  &:not(:last-of-type) {
    margin-right: 0.5em;
  }
  &.is-active::before {
    opacity: 1;
  }
  @media ( max-width: 48em ) {
    font-size: 0.75em;
    i {
      background-color: rgba(255,255,255,0.2);
    }
  }
`

const Radio = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        ambient: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Ambient"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        classical: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Classical"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        electronic: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {nin: ["Ambient", "Classical", "Hip-hop"]}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        hipHop: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Hip-hop"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
      }
    `
  )

  const { 
    loadAndPlayTracks,
    showPlayer
  } = usePlayer()

  const [currentStation, setCurrentStation] = useState(``)

  function getShuffledTracks( tracks ) {
    let tracksArray = []

    for ( let { node } of tracks ) {
      tracksArray.push( node )
    }
    
    return tracksArray.sort(function() {
      return .5 - Math.random()
    })

  }

  const ambientTracks = getShuffledTracks( data.ambient.edges ) || false
  const classicalTracks = getShuffledTracks( data.classical.edges ) || false
  const electronicTracks = getShuffledTracks( data.electronic.edges ) || false
  const hipHop = getShuffledTracks( data.hipHop.edges ) || false

  function playGenre( tracks, station ) {
    loadAndPlayTracks( tracks )
    setCurrentStation( station )
    showPlayer()
  }

  function handleGenreClick( tracks, station ) {
    playGenre( tracks, station)
    props.setShowRadio(0)
    props.hideNav()
  }

  useEffect(() => {

  })

  return (
    <StyledRadio {...props}>
      {/*
      <div>
        <StyledRadioLogo>
          <Logo />
        </StyledRadioLogo>
        <span>Radio</span>
      </div>
      <div>
      */}
        <StyledRadioButton onClick={(e) => handleGenreClick( ambientTracks, `ambient` )} className={currentStation === `ambient` ? `is-active` : null}>Ambient <i>{ambientTracks.length} tracks</i></StyledRadioButton>
        <StyledRadioButton onClick={(e) => handleGenreClick( classicalTracks, `classical` )} className={currentStation === `classical` ? `is-active` : null}>Classical <i>{classicalTracks.length} tracks</i></StyledRadioButton>
        <StyledRadioButton onClick={(e) => handleGenreClick( electronicTracks, `electronic` )} className={currentStation === `electronic` ? `is-active` : null}>Electronic <i>{electronicTracks.length} tracks</i></StyledRadioButton>
        <StyledRadioButton onClick={(e) => handleGenreClick( hipHop, `hip-hop` )} className={currentStation === `hip-hop` ? `is-active` : null}>Hip-hop <i>{hipHop.length} tracks</i></StyledRadioButton>
      {/*</div>
      <StyledRadioArrow />*/}
    </StyledRadio>
  )
}

export { StyledRadio }
export default Radio
